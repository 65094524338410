import { ResetCSS } from 'common/assets/css/style';
import Seo from 'components/seo';
import ErrorSec from 'containers/Error';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle, { ContentWrapper } from 'containers/app.style';
import useDarkMode from 'use-dark-mode';
import { darkTheme } from 'common/theme/dark/darkTheme';
import { lightTheme } from 'common/theme/light/lightTheme';

const NotFoundPage = () => {
  const darkMode = useDarkMode();
  const theme = darkMode.value ? darkTheme : lightTheme;

  return (
  <ThemeProvider theme={theme}>
      <Seo title="ICHI" />
      <ResetCSS />
      <GlobalStyle />
      <div style={{position: 'relative'}}>
        <ContentWrapper className=''>
          <ErrorSec />
        </ContentWrapper>
      </div>
  </ThemeProvider>
)};

export default NotFoundPage;
